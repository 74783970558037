.supportpopup {
  height: 80px;
  width: 80px;
  position: fixed;
  bottom: 0;
  right: 5px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
  -moz-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
  box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
  background-color: #093d72;
  border: 1px solid #011233;
  &.h:hover {
    background-color: #041f52;
    transition: 0.5s;
    cursor: pointer;
  }
}
.supportpopupminimizer {
  height: 20px;
  width: 80px;
  position: fixed;
  bottom: 0;
  right: 5px;
  background-color: #093d72;
  color: white;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  &.h:hover {
    cursor: pointer;
    background-color: #041f52;
    transition: 0.5s;
    border-radius: 8px;
  }
  span {
    padding-top: 3px;
    padding-bottom: 8px;
  }
}
.hidetext {
  display: inline;
}
.supportpopupmini {
  background-color: #093d72;
  height: 80px;
  width: 15px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  &:hover {
    cursor: pointer;
  }
  &.h {
    width: 30px;
    transition: 0.5s;
  }
  span {
    writing-mode: vertical-lr;
    margin-top: 12px;
    font-size: 12px;
    text-align: center;
    color: white;
  }
}
.supportbox {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 80px;
  width: 80px;
  opacity: 0;
  position: fixed;
  bottom: 50px;
  right: 5px;
  &:hover {
    cursor: pointer;
  }
}
.helpIcon {
  height: 80px;
  width: 80px;
  svg {
    height: 60px;
    width: 60px;
    margin-top: 10px;
    path {
      &:first-child {
        fill: #00800011;
      }
    }
  }
}
.chat-controls {
  border-radius: 10px 10px 0 0px;
  background-color: #041f52;
  display: flex;
  flex-direction: row-reverse;
  svg {
    height: 30px;
    width: 30px;
    margin: 0 0px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: -6px;
    &:first-child {
      margin-right: 5px;
    }
  }
}
.send-msg-btn {
  background-color: #1230cc;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 3px;
  color: white;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    background-color: #0f20aa;
  }
}
.chat-content {
  background-color: #093d72;
}
.chat-input {
  background-color: #093d72;
  border-radius: 0 0 10px 10px;
  form {
    div {
      .ant-row {
        margin-bottom: 3px;
        input {
          margin-top: 3px;
        }
      }
    }
  }
}
.server-msg {
  color: white;
  background-color: #041f52;
  border-radius: 10px;
  display: block;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 2px;
  margin-top: 3px;
  padding: 5px;
  font-weight: 400;
}
.thankyoumsg {
  height: 200px;
  width: 200px;
  position: fixed;
  bottom: 0;
  right: 5px;
  -webkit-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
  -moz-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
  box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
  background-color: #093d72a0;
  color: white;
  font-weight: 400;
}
