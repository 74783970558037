/* @font-face {
  font-family: 'Futura PT Light';
  src: local('Futura PT Light'), url(./fonts/FuturaPTLight.otf) format('opentype');
}
@font-face {
  font-family: 'Futura PT Book';
  src: local('Futura PT Book'), url(./fonts/FuturaPTBook.otf) format('opentype');
}
@font-face {
  font-family: 'Futura PT Bold';
  src: local('Futura PT Bold'), url(./fonts/FuturaPTBold.otf) format('opentype');
} */
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700,&display=swap');
body {
  margin: 0;
  font-family: 'Jost', sans-serif !important;
  font-weight: 300 !important;
  color:#22395e !important;
  background: #030b22 url(./images/bg.jpg) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  font-size:19px !important;
}
.inner-pages {
  background: #030b22 url(./images/bg2.jpg) no-repeat top center;
  background-size: cover;
  background-attachment: fixed;
}
.container{
  max-width:1200px;
  margin:0px auto;
  padding-left:15px;
  padding-right:15px;
}
.ant-modal-mask{
  background-color: rgba(21, 32, 44, 0.95) !important;
  color:#fff !important;
}
.ant-modal-close{
  color:#fff !important
}
.ant-modal-footer{
  display: none;
}
.ant-modal-content{
  background:transparent !important;
  box-shadow: none !important;
}
.ant-modal-body{
  padding:0px !important;
}