// @font-face {
//   font-family: 'Futura PT Light';
//   src: local('Futura PT Light'), url(./fonts/FuturaPTLight.otf) format('opentype');
// }
// @font-face {
//   font-family: 'Futura PT Book';
//   src: local('Futura PT Book'), url(./fonts/FuturaPTBook.otf) format('opentype');
// }
// @font-face {
//   font-family: 'Futura PT Bold';
//   src: local('Futura PT Bold'), url(./fonts/FuturaPTBold.otf) format('opentype');
// }
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600;700&display=swap");
body {
  overflow: visible !important;
}
.container {
  max-width: 1135px;
}
.img-fluid {
  width: 100%;
}
.login-form {
  background: url(./images/bg2.png) no-repeat;
  padding: 40px;
  color: #fff;
  max-width: 420px;
  box-sizing: border-box;
  margin: 0px auto;
  background-size: cover;
  h6 {
    font-size: 19px;
    text-transform: uppercase;
    color: #4fa6d6;
    margin: 0px 0 10px 0;
    padding: 0px;
    text-align: center;
    letter-spacing: 3px;
    font-weight: 300;
  }
  input[type="text"],
  input[type="password"],
  select {
    width: 100%;
    height: 60px;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
    -moz-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
    box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
    background: url(./images/input-box.png) no-repeat right center;
    color: #fff;
    font-size: 17px;
    color: #95eafe;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 25px;
    background-size: 100% 100%;
    border: 0px;
  }
  input[type="text"]:focus,
  input[type="password"]:focus,
  select:focus {
    border: 0px;
    outline: 0px;
  }
  .ant-input-affix-wrapper {
    background: transparent;
    border: 0px;
  }
  .ant-input-affix-wrapper.ant-input-password {
    display: inline-flex;
    background: url(./images/input-box.png) no-repeat right center;
    padding: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    background-size: 100% 100%;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
    -moz-box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
    box-shadow: 0px 0px 13px 0px rgba(68, 124, 170, 0.3);
    margin-top: 10px;
    input#basic_password,
    input#basic_old_password,
    input#basic_new_password {
      margin: 0px;
      background: none;
      box-shadow: none;
    }
    span {
      display: flex;
      padding: 0px;
    }
  }
  .ant-form.ant-form-horizontal span {
    display: inherit;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 20px;
  }
  .ant-input-password-icon {
    margin-right: 20px;
  }
  .ant-input-password-icon:hover {
    color: #95eafe;
  }
  .ant-checkbox-inner {
    background: url(./images/uncheck.png) no-repeat left top;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 0px;
    background-size: 100%;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    background: url(./images/check.png) no-repeat left top;
    height: 25px;
    width: 25px;
    background-size: 100%;
    background-color: transparent;
    border: 0px;
    transform: rotate(0deg) scale(1) translate(0%, 0%);
    opacity: 1;
    transition: all 0.2s !important;
    left: 0%;
    top: 0px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border: 0px;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 0px !important;
  }
  .ant-checkbox-checked::after {
    border: 0px;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #95eafe !important;
  opacity: 1;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #95eafe !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #95eafe !important;
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #95eafe !important;
  opacity: 1;
}
.checkbox-d {
  margin-top: 30px;
}
.checkbox-custom span {
  font-size: 18px;
  font-family: "Jost", sans-serif;
  font-weight: 300;
}

.button-m {
  background: url(./images/button-shadow.png) no-repeat left top;
  margin: 0px auto;
  box-sizing: border-box;
  width: 340px;
  height: 95px;
  padding: 0px 20px;
  -webkit-box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  -moz-box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  .submit-btn {
    background: url(./images/button-bg.png) no-repeat left top;
    color: #fff;
    font-size: 27px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px;
    width: 100%;
    display: block;
    background-size: 100% 100%;
    height: 74px;
    line-height: 63px;
    cursor: pointer;
    span {
      color: #fff;
    }
  }
  &.request-size {
    height: 68px;
  }
  .request-btn {
    background: url(./images/button-bg.png) no-repeat left top;
    color: #fff;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px;
    width: 100%;
    display: block;
    background-size: 100% 100%;
    height: 56px;
    line-height: 38px;
    cursor: pointer;
    span {
      color: #fff;
    }
    &:hover{
      background: url(./images/button-bg.png) no-repeat left top !important;
    }
  }
  .ant-btn.submit-btn:hover,
  .ant-btn.submit-btn:active,
  .ant-btn.submit-btn:focus {
    background-color: transparent;
    background: url(./images/button-bg.png) no-repeat left top;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px;
    background-size: 100% 100%;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.button-j {
  margin: 0px auto;
  box-sizing: border-box;
  width: 450px;
  height: 95px;
  padding: 0px 20px;
  -webkit-box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  -moz-box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  .submit-btn {
    background: url(./images/button-bg.png) no-repeat left top;
    color: #fff;
    font-size: 27px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px;
    width: 100%;
    display: block;
    background-size: 100% 100%;
    height: 74px;
    line-height: 63px;
    cursor: pointer;
    span {
      color: #fff;
    }
  }
  .ant-btn.submit-btn:hover,
  .ant-btn.submit-btn:active,
  .ant-btn.submit-btn:focus {
    background-color: transparent;
    background: url(./images/button-bg.png) no-repeat left top;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px;
    background-size: 100% 100%;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}
.button-m2 {
  background: url(./images/button-shadow.png) no-repeat left top;
  margin: 0px auto;
  box-sizing: border-box;
  width: 340px;
  height: 95px;
  padding: 0px 20px;
  -webkit-box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  -moz-box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  box-shadow: -2px 2px 7px 0px rgba(41, 84, 107, 0.32);
  .submit-btn {
    background: url(./images/button-bg2.png) no-repeat left top;
    color: #dcf2fe;
    font-size: 25px;
    letter-spacing: 6px;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px;
    width: 100%;
    display: block;
    background-size: 100% 100%;
    height: 67px;
    line-height: 60px;
    text-align: center;
  }
  .ant-btn.submit-btn.ant-btn-primary:hover,
  .ant-btn.ant-btn-primary.submit-btn:active,
  .ant-btn.ant-btn-primary.submit-btn:focus {
    background-color: transparent !important;
    background: url(./images/button-bg2.png) no-repeat left top !important;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px !important;
    background-size: 100% 100% !important;
  }
  .ant-btn.submit-btn:hover,
  .ant-btn.submit-btn:focus,
  .ant-btn.submit-btn:active {
    background-color: transparent !important;
    background: url(./images/button-bg2.png) no-repeat left top !important;
    text-transform: uppercase;
    font-family: "Jost", sans-serif;
    font-weight: 400;
    border: 0px !important;
    background-size: 100% 100% !important;
    color: #fff;
  }
}

.login-min {
  span {
    color: #95eafe;
  }
  em {
    color: #95eafe;
    font-size: 17px;
    display: block;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 70px;
    font-style: normal;
    a {
      color: #dcf2fe;
      margin-left: 3px;
      text-decoration: underline;
    }
  }
}
footer {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: transparent !important;
}
.ant-upload {
  .ant-btn:focus {
    background: transparent;
  }
}
.ant-checkbox-group {
  .ant-checkbox-group-item {
    color: #95eafe;
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.ant-checkbox-indeterminate .ant-checkbox-inner::afte {
  background-color: transparent !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: transparent !important;
}
.ant-radio-wrapper {
  .ant-radio-inner {
    background: url(./images/uncheck.png) no-repeat left top;
    height: 25px;
    width: 25px;
    background-color: transparent;
    background-size: 100%;
    border: 0px;
    border-radius: 0px;
  }
}
.ant-radio-checked .ant-radio-inner::after {
  background: url(./images/check.png) no-repeat left top;
  height: 25px;
  width: 25px;
  background-size: 100%;
  background-color: transparent;
  border: 0px;
  transform: rotate(0deg) scale(1) translate(0%, 0%);
  opacity: 1;
  transition: all 0.2s !important;
  left: 0%;
  top: 0px;
}
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio:hover .ant-radio-inner,
.ant-radio-inner:focus + .ant-radio-inner {
  border: 0px !important;
}
.ant-checkbox-checked::after {
  border: 0px;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked::after {
  border: 0px !important;
}
.left-logo {
  -webkit-box-shadow: 0px 0px 12px 0px rgba(163, 197, 247, 0.29);
  -moz-box-shadow: 0px 0px 12px 0px rgba(163, 197, 247, 0.29);
  box-shadow: 0px 0px 12px 0px rgba(163, 197, 247, 0.29);
  border-radius: 100px;
  margin-top: 20px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: auto;
  height: auto;
  background: transparent;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: transparent !important;
}
.checkbox2 {
  width: 23%;
}
.ant-upload-list .ant-tooltip {
  display: none !important;
}
.inner-pages {
  overflow: visible !important;
}

.popup-btn-error {
  background: url(images/button-bg.png) no-repeat left top !important;
  background-size: 100% 100% !important;
}
.conf-password .conf-password-input input#basic_passwordagain {
  margin: 0px;
  background: none;
  box-shadow: none;
}
.tab-style-menu {
  text-align: right;

  a {
    background-color: #edf6fd;
    min-height: 57px;
    display: inline-block;
    margin-left: 5px;
    line-height: 37px;
    padding: 10px 20px;
    color: #8aabcd;
    text-transform: uppercase;

    &:active,
    &:hover {
      color: #8aabcd;
      background-color: #fff;
    }
  }

  button {
    background-color: #edf6fd;
    min-height: 57px;
    display: inline-block;
    margin-top: -2px;
    margin-left: 5px;
    line-height: 37px;
    padding: 10px 20px;
    color: #8aabcd;
    text-transform: uppercase;
    border-radius: 0;
    border: 0;
    box-shadow: none;

    &:active,
    &:hover {
      color: #8aabcd;
      background-color: #fff;
    }
  }
}

@media (max-width: 991px) {
  .upper-logo {
    width: 100%;
  }
  .button-m2 {
    width: 220px;
    .submit-btn {
      letter-spacing: 2px;
      font-size: 17px;
    }
  }
}
.loading-fallback {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dbluebackground {
  background: url(images/dblueback.png);
}
.metrics-bground{
  background: url(images/metrics.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}